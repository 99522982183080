body {
  padding: 0% !important;
  margin: 0%;
  overflow-x: hidden;
  font-family : Rubik , sans-serif !important;
}
.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem #fff;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Login Form CSS Start */

.loginForm {
  margin : auto;
  box-shadow : 1px 1px 3px 3px #888888;
  border : 1px solid #dee2e6; 
  margin-top: 8rem;
}

.loginBtn {
  border: none !important;
  background-color: #FBC02D !important;
  box-shadow: 0px 5px 5px #dadada !important;
  color: #000 !important;
  padding: 12px 30px !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Login Form CSS End */

/* Header css start */

.no-decoration {
  text-decoration: none !important;
  color: black !important;
}

.web-box {
  display: -webkit-box !important;
  /* padding-left: 0% !important;
  text-align: right !important; */
} 

.user-logout {
  grid-column: 4/6;
  display: flex;
  justify-content: space-around;
  /* grid-template-columns: 2fr repeat(2, 1fr 2fr 1fr); */
  text-align: right !important;
  align-items: center;
}

.spacing {
  padding-top : 0 !important;
  margin : 0% 1.5% 1% 1.5% !important;
  font-family : Rubik , sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
 }
 .dropdown-item {
  font-size: 14px !important;
}
 .borBtm {
    border-bottom : 2px solid #FBC02D;
 }

 .user-name {
  color: black;
  font-size: 16px;
  font-weight: 500;
  font-family : Rubik , sans-serif;
}

.nav-link {
  display: block !important;
  padding: .2rem .6rem !important;
  color: #3f3f3f !important;
  text-decoration: none !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out !important;
}

@media screen and (max-width: 768px) {
  .navbar-collapse {
    width: 40% !important;
    position: absolute !important;
    left: 0% !important;
    top: 5rem !important;
  }

  .user1{
    display: none !important;
  }

  .navbar-toggler {
    position: absolute !important;
    top: 0% !important;
    right: 0% !important;
  }

  .dropdown-item {
    font-size: 12px;
  }

  .web-box {
    display: none !important;
  }

}

@media (min-width: 768px) and (max-width: 1199px) {
  .borBtm {
    border-bottom : 2px solid #FBC02D;
    /* width: 145% !important; */
  }
}

.nav {
  flex-wrap: nowrap !important;
}

.container {
  font-family: Rubik , sans-serif !important;
  /* display: flex !important;  */
  /* or inline-flex */
}
/* Header css end */

/* Buttons css start */
.create-button {
  border: none !important;
  border-radius: 4px !important;
  font-weight: bolder !important;
  text-decoration: none !important;
  font-size: 14px !important;
  height: 34px !important;
}
.create-button1 a{
  color: #ffffff;
}
.create-button1 {
  border: none;
  background: #FBC02D;
  color: #ffffff;
  border-radius: 4px;
  padding: 3px 20px;
  font-weight: 600;
  justify-content: center;
  text-decoration: none;
  /* display: inline-flex; */
  font-size: 12px;
  height: 34px;
  width: 150px;
  margin-top: 8%;
}
.archiveBtn{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.bookmarkBtn{
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 80px;
}
.submitOffer {
  border: none;
  background: #40bf80;
  color: #ffffff;
  border-radius: 4px;
  padding: 3px 20px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin-right: 15px;
  height: 34px;
  width: 150px;
}
.accordion-button {
  position: relative !important;
  display: initial !important;
  padding: 0rem !important;
  color: #212529 !important;
  transition:  .15s ease-in-out;
  box-shadow: none !important;
}
.completeUnset {
  all : unset !important;
  cursor : pointer !important;
}
.completed {
  font-size : 22px;
  color : #00b359;
}
/* Buttons css end */

/* Create Post Form Css Start */

.label-title{
  color: #6E7687 !important;
  /* font-weight: 600 !important; */
}

.astrick{
  color: #ff0000;
}

.radio-toolbar {
  margin: 10px 20px;
}

/* .radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
} */

.radio-toolbar label {
    padding: 0px 15px;
    font-family: 'Rubik', sans-serif;
  }

  /* .radio-toolbar label:hover {
    background-color: #999;
  } */

  .radio-toolbar input[type="radio"]:focus{
      border: none;
      border-radius: 5px;
      padding: 12px 25px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      font-family: 'Rubik', sans-serif;
      box-shadow: 0px 5px 5px #dadada;
  }

  .radio-toolbar input[type="radio"]:checked {
      background-color: rgb(214, 13, 13);
      color: rgb(248, 0, 0);
      border-color: #000000;
  }

  .astrick{
    color: #ff0000;
  }

  .select-dropdown {
    width: 100%;
    color: rgba(110, 118, 135, 0.6);;
    border: 1px solid #BDC9D7;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
  }
/* Create Post Form Css End */

/* Homepage start CSS */

.alignSCenter {
  align-self: center !important;
}

/* Filters Css start */
.pml-5 {
  padding-left: 5px;
  margin-left: 5px;
}
.mw-32 {
  min-width: 32em !important;
}

#blur {
  color: transparent;
  text-shadow: 0 0 7px #000;
}
/* Filters Css End */

/*Shipment Type css start*/
.export{
  border: 1px solid #0068FF;
  color: #0068FF;
}
.import{
  border: 1px solid #1ECD93;
  color: #1ECD93;
}
.domestic{
  border: 1px solid #404E6C;
  color: #404E6C;
}
.font10{
  font-size: 11px;
  font-family: 'Rubik', sans-serif !important;
}
.font12{
  font-size: 12px !important;
}
.font14{
  font-size: 14px !important;
}
.lh-15{
  line-height: 15px;
}
.grey {
  color: #888;
}
.ship{
  border-radius: 6px;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 3px;
}

.post-row {
  padding: 10px 0;
}
.post-row:nth-child(2n - 2){
  background-color: #F2F2F2;
}
/*Shipment Type css end*/

.company-name{
  font-family: 'Rubik', sans-serif;
  font-size: 12px; 
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #727272;
  line-height: 10px !important;
}

.category-name{
  font-size: 12px; 
  letter-spacing: 0.5px;
  color: #727272;
  font-weight: 600;
}

.post-type{
  color: #727272;
  font-size: 12px; 
  font-weight:bolder;
  letter-spacing: 0.5px;
}

/* Homepage End CSS */


/* Header1 start */
.flexbox-container {
  display: flex;
  justify-content: space-between;
}

.flexbox-item-1 {
  width: 20%;
}
.flexbox-item-2 {
  width: 60%;
  /* justify-content: center; */
}
.flexbox-item-3 {
  width: 20%;
  justify-content: flex-end !important;
  /* display: -webkit-box !important; */
}
.logo-height {
  width: 185px;
  height: 30px;
}

/* Nav CSS Start */

.container-fluid {
  display: inline-flex; /* or inline-flex */
  /* flex-direction: row; */
}
.plr-7 {
  padding: 0px 7px 0px !important;
}
.pl-14 {
  padding-left: 14px !important;
}
.pr-14 {
  padding-right: 14px !important;
}
/* Nav CSS End */

/*--FOOTER START--*/
.tb-footer {
  background: #3F434F;
  margin-top: 40px;
}
.footer-container{
padding: 0 100px;
}
.footer-name {
  padding: 20px 0;
}
.footer-name a{
  color: white;
  padding: 20px 0;
}
.footer-name a:hover{
  color: #FBC02D;
  padding: 20px 0;
}
.footer-logo {
  display: flex;
  justify-content: end;
  height: auto;
}
.footer-top {
  padding: 20px 0;
}
.footer-bottom {
  height: 40px;
  line-height: 40px;
  background: white;
}
.white {
  color: white;
  text-decoration: none;
  font-family: 'Rubik', sans-serif;
}
.footer-logoHt {
  background-color : white; 
  height : auto;
}
/*--FOOTER END--*/

/* ******************* Payments UpdateWallet START ********************************* */
.walletHeader {
  padding-top: 1rem;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
}
.walletAmt {
  color: #39ac73;
}
.no-data{
  text-align: center;
  margin-top: 20vh;
}
.wallet-transaction {
  display: grid;
  grid-template-columns: 80px 0.4fr 0.8fr 80px;
  height: 60px;
  border-bottom: 2px solid #808182;
  cursor: pointer;
}
.wallet-transaction .wallet-one {
  display: grid;
  align-items: center;
  font-size: 30px;
  color: #808182;
  justify-content: center;
}
.wallet-transaction .wallet-two {
  display: grid;
  grid-template-rows: auto auto;
  align-self: center;
}
.wallet-transaction .wallet-two .wallet-two-details{
  font-size: 14px;
  color: #3F434F;
}
.wallet-transaction .wallet-two .wallet-two-info {
  font-size: 12px;
  color: #3F434F;
}
.wallet-transaction .wallet-two .wallet-two-info .buy-sell-info{
  font-size: 12px;
  color: #FBC02D;
}
.wallet-transaction .wallet-three {
  display: grid;
  grid-template-columns: 190px 110px;
  align-self: center;
}
.wallet-transaction .wallet-three .wallet-three-date{
  grid-column: 1;
  font-size: 14px;
  color: #3F434F;
}
.wallet-transaction .wallet-three .wallet-three-time{
  grid-column: 2;
  font-size: 14px;
  color: #3F434F;
}
.wallet-transaction .wallet-four {
  display: grid;
  grid-template-rows: auto auto;
  align-self: center;
}
.BorHt-0 {
  border-bottom: none !important;
  height: 0% !important;
}
.font15 {
  font-size: 15px;
}
/* ******************* Payments UpdateWallet END ********************************* */

/* COMMON for All pages Start */
.empty-height {
  min-height: 200px;
}
.breadcrumbs{
  padding: 12px 0px;
  border-bottom: solid #D6E1EE;
  padding-left: 20px;
  margin: 0px 15px;

}
.breadcrumb-link {
  color: #B0B3B6 !important;
  font-size: 16px;
  text-decoration: none;
  background-color: transparent;
}
.currency {
  color: #fbc02d; 
  font-size: 16px;
  font-weight: bold;
}
.padLeft-3 {
  padding-left: 3% !important;
}
.ml5 {
  margin-left: 5%;
}
.btnPad {
  padding: 18px 30px 35px;
  border-right: 2px solid #888;
  height: 10px;
  align-self: center  ;
}
.gridColumn {
  grid-template-columns: 80px 2.4fr 0.8fr 80px !important;
}
/* COMMON for All pages End */


  /* ******************* CHAT SYSTEM CSS STARTS ********************************* */
  .primary{
    background-color: #fbc02d;
    padding-left: 8px;
  }
  .ht500 {
    height: 500px;
  }
  .pad{
    padding-left: 0% !important;
    padding-right: 1px !important;
  }
  .borGrey {
    border : 1px solid #888;
  }
  .borBtmGrey {
    border-bottom : 1px solid #888 !important;
  }
  .postType {
    border-right: 2px solid #666;
    color: #fbc02d;
  }
  .list {
    width: 100%;
    float: left;
    background: #fff;
    height: 500px;
    border:1px solid #888;
    /*border-right: 1px solid #fff;*/
  }
  .list ul {
    list-style-type: none;
    padding: 0;
  }
  .list ul li a {
    display: block;
    border: none;
    /*border-left: 5px solid transparent;*/
    cursor: pointer;
    text-decoration: none;
    padding: 0px 5px;
    color: #888;
  }
  .list ul li a:hover {
    border-left: 5px solid #E57373;
  }
  .list ul li:first-child {
    color: #333;
    font-size: 14px;
    font-weight: 600;
    min-height: 50px;
  }
  .chat-box {
    float: left;
    width: 100%;
    background: #fff;
    height: 498px;
  }
  .chat-box ul {
    list-style-type: none;
    padding: 0;
    cursor: pointer;
  }
  
  .overflow-x{
    overflow-x: scroll;
    width: 100%;
  }
  
  .overflow-y{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 416px;
  }
  
  .username{
      font-family: medium-content-sans-serif-font, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-weight: bold;
      font-size: 15px;
      color: rgb(117, 117, 117);
  }
  .chat-head{
    background-color:rgb(242, 242, 242);
    margin-left: 32px;
    padding: 8px 11px;
    border-radius: 13px 5px 5px 15px;
    position: relative;
  }
  
  .chat-box ul:target {
    display: contents;
  }
  .chat-box ul li .title {
    color: #000;
    width: 25% !important;
    display: inline-block;
    min-height: 40px;
    border-right: 1px solid #888;
  }
  .chat-box ul li .me {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .chat-box ul li .you {
    display: flex;
    align-items: center;
  }
  .chat-box ul li > div .img {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    margin-bottom: -25px;
    border: 2px solid #03A9F4;
    z-index: 100;
    position: relative;
  }
  .chat-box ul li > div .name {
    display: inline-block;
    padding: 5px 50px 5px 20px;
    margin-top: -20px;
    margin-right: -30px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 15px 0 0 0;
    font-size: 13px;
    position: relative;
  }
  .chat-box ul li > div .text div {
    background-color: lightgrey;
    display: inline-block;
    padding: 10px 10px;
    max-width: 200px;
    min-width: 100px;
    /*margin-left: -55px;*/
    /*border-radius: 10px 0 10px 10px;*/
    z-index: 10;
    position: relative;
  }
  .chat-box ul li .you .name {
    padding: 5px 25px 5px 50px;
    margin-left: -30px;
    /*border-radius: 0 15px 0 0;*/
  }
  .chat-box ul li .you .text div {
    margin-left: 6px;
    /*border-radius: 0 10px 10px 10px;*/
    text-align: left;
  }
  
  .message_container{
    height: 330px;
    overflow-y: scroll;
    overflow-x: auto;
  }
  .msgbox {
    margin-top: 15px;
    height: 81px;
    border: 1px solid #999;
    width: 100%;
  }
  
  .msger-inputarea {
    /* margin-top: 12px; */
    display: flex;
    padding: 10px;
    border-top: var(--border);
    background: #eee;
  }
  .msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
  }
  .msger-input {
    flex: 0.97;
    background: #ddd;
  }
  .msger-send-btn {
    margin-left: 10px;
    background: #fbc02d;
    padding: 0px 20px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
  }
  .msger-send-btn:hover {
    background: rgb(0, 180, 50);
  }
    
  .visibleN {
    visibility: hidden;
  }

  .visibleY {
    visibility: visible;
  }

  /*Drop Up content for dialogue chat trade*/
.dropup {
  position: relative;
  display: inline-block;
}

.dropup-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 5px 5px #dadada;
  min-width: 160px;
  font-size: 12px !important;
  bottom: 50px;
  left: 1%;
  z-index: 1;
}

.dropup-content a {
  color: black;
  padding: 0px !important;
  text-decoration: none;
  display: block;
}

.dropup:hover .dropup-content {
  display: block;
}

.dropup:hover .dropbtn {
  background-color: #2980B9;
}
/*Drop Up content for dialogue chat trade End*/

  /* ******************* CHAT SYSTEM CSS ENDS ********************************* */